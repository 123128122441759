import { createSlice } from '@reduxjs/toolkit'
import { amplifyService } from '../../../api/Amplify'
import { PASSWORD_ERROR_MSG, PASSWORD_FORGOT_ERROR_MSG } from '../../../utils/Constants'

const authenticationSlice = createSlice({
    name: "authentication",
    initialState: {
        role                : "0",
        authFactoryId       : "0",
        token               : "",
        email               : "",
        phoneNumber         : "",
        loggedIn            : false,
        loggedOut           : false,
        error               : false,
        errorMessage        : PASSWORD_ERROR_MSG,
        isLoading           : false,
        resetPassword       : false,
        resetCompleted      : false,
        newPasswordRequired : false,
        menuSelectedIndex   : 0,
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        loginSuccess: (state) => {
            state.loggedIn = true
        },
        loginFailed: (state, action) => {   
            state.loggedIn = false
            state.error = true
            state.errorMessage = action.payload
        },
        setRole: (state, action) => {
            state.role = action.payload
        },
        setResetPassword: (state, action) => {
            state.resetPassword = action.payload
        },
        setPasswordError: (state, action) => {
            state.error = action.payload
        },
        setPasswordErrorMsg: (state, action) => {
            state.errorMessage = action.payload
        },
        setNewPasswordRequired: (state, action) => {
            state.newPasswordRequired = action.payload
        },
        setLogout: (state) => {
            state.role = "0"
            state.authFactoryId = "0"
            state.token = ""
            state.email = ""
            state.phoneNumber = ""
            state.loggedIn = false
            state.loggedOut = true
            state.error = false
            state.errorMessage = PASSWORD_ERROR_MSG
            state.isLoading = false
            state.resetPassword = false
            state.resetCompleted = false
            state.newPasswordRequired = false
            state.menuSelectedIndex = 0
        },
        setMenuSelectedIndex: (state, action) => {
            state.menuSelectedIndex = action.payload
        },
        setAuthFactoryId: (state, action) => {
            state.authFactoryId = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload
        },
        setResetCompleted: (state, action) => {
            state.resetCompleted = action.payload
        },
        setOtpError(state, action) {
            state.otpError = action.payload
        },
    }
})

export const { 
    startLoading, 
    stopLoading, 
    loginSuccess, 
    loginFailed, 
    setRole,
    setResetPassword,
    setNewPasswordRequired,
    setPasswordError,
    setPasswordErrorMsg,
    setLogout,
    setMenuSelectedIndex,
    setAuthFactoryId,
    setToken,
    setEmail,
    setPhoneNumber,
    setResetCompleted,
    setOtpError
} = authenticationSlice.actions

export const checkUserCurrentStatus = () => dispatch => {
    
    amplifyService.checkUserCurrentStatus()
        .then((user) => {
            
            if (user !== undefined) {

                const userRole = 
                    user.attributes["custom:role"]

                const userAuthFactoryId = 
                    user.attributes["custom:factory_id"]

                const email = user.attributes["email"]
                const phoneNumber = user.attributes["phone_number"]

                dispatch(setRole(userRole))
                dispatch(setAuthFactoryId(userAuthFactoryId))
                dispatch(setEmail(email))
                dispatch(setPhoneNumber(phoneNumber))
                dispatch(loginSuccess())
                dispatch(getToken())

            }                    
            
        })
        .catch((error) => {
        //     console.error("checkUserCurrentStatus", error)
        })
        
}

export const loginToCognito = (email, password) => dispatch => {

    dispatch(startLoading())
    
    amplifyService.loginToCognito(email, password)
        .then((user) => {            
            
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {

                dispatch(setNewPasswordRequired(true))
                dispatch(stopLoading())

            } else {

                const userRole = 
                    user.attributes["custom:role"]

                const userAuthFactoryId = 
                    user.attributes["custom:factory_id"]

                const email = user.attributes["email"]
                const phoneNumber = user.attributes["phone_number"]
                
                dispatch(setRole(userRole))
                dispatch(setAuthFactoryId(userAuthFactoryId))
                dispatch(setEmail(email))
                dispatch(setPhoneNumber(phoneNumber))
                dispatch(stopLoading())
                dispatch(loginSuccess())
        
            }

        })
        .catch((error) => {
            
            dispatch(loginFailed(error.message))
            dispatch(stopLoading())
            console.log(error)
            switch (error.code) {

                case "NotAuthorizedException":
                    break

                case "UserNotFoundException":
                    break

                case "PasswordResetRequiredException":

                    dispatch(forgotPassword(email))
                    dispatch(setResetPassword(true))

                    break

                default:
                    break

            }

        })

}

export const logoutFromCognito = () => dispatch => {

    amplifyService.logoutFromCognito()
        .then(() => {
            dispatch(setLogout())
        })

}

export const forgotPassword = (email) => dispatch => {

    amplifyService.forgotPassword(email)
    dispatch(setPasswordErrorMsg(PASSWORD_FORGOT_ERROR_MSG))
    dispatch(setResetPassword(true))

}

export const forgotPasswordSubmit = (email, otp, password) => dispatch => {

    dispatch(startLoading())

    amplifyService.forgotPasswordSubmit(email, otp, password)
        .then(() => {
            
            dispatch(setResetPassword(false))
            dispatch(setResetCompleted(true))
            dispatch(stopLoading())

        })
        .catch(error => {
            
            dispatch(setPasswordError(true))
            dispatch(setPasswordErrorMsg(error.message))
            dispatch(stopLoading())

            switch (error.code) {

                case "InvalidPasswordException":
                    break

                case "CodeMismatchException":
                    dispatch(setOtpError(true))
                    break

                case "LimitExceededException":
                    break
                    
                default:
                    break

            }

        })
}

export const newPasswordRequiredSubmit = (email, oldPassword, newPassword) => dispatch => {

    dispatch(startLoading())

    amplifyService.loginToCognito(email, oldPassword)
        .then((user) => {

            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {

                amplifyService.newPasswordRequiredSubmit(user, newPassword)
                    .then(() => {

                        dispatch(setResetPassword(false))
                        dispatch(setNewPasswordRequired(false))
                        dispatch(setResetCompleted(true))
                        dispatch(stopLoading())

                        // amplifyService.loginToCognito(email, newPassword)
                        //     .then((user) => {

                        //         const userRole = 
                        //             user.attributes["custom:role"]
                
                        //         const userAuthFactoryId = 
                        //             user.attributes["custom:factory_id"]
                
                        //         const email = user.attributes["email"]
                        //         const phoneNumber = user.attributes["phone_number"]
                
                        //         dispatch(setRole(userRole))
                        //         dispatch(setAuthFactoryId(userAuthFactoryId))
                        //         dispatch(setEmail(email))
                        //         dispatch(setPhoneNumber(phoneNumber))
                        //         dispatch(stopLoading())
                        //         dispatch(loginSuccess())
                                
                        //     })
                        //     .catch(() => {

                        //     })

                    })
                    .catch((e) => {
                        console.error("XXX", e)
                    })
            }
        })
        .catch(() => {

        })
}

export const getToken = () => dispatch => {

    amplifyService.getAccessToken()
        .then((token) => {
            dispatch(setToken(token))
        })
        // .catch((error) => {
        //     console.error(error)
        // })

}

export const selectRole = (state) => state.authentication.role
export const selectUserLoggedIn = (state) => state.authentication.loggedIn
export const selectUserLoggedOut = (state) => state.authentication.loggedOut
export const selectIsLoading = (state) => state.authentication.isLoading
export const selectPasswordError = (state) => state.authentication.error
export const selectPasswordErrorMsg = (state) => state.authentication.errorMessage
export const selectResetPassword = (state) => state.authentication.resetPassword
export const selectNewPasswordRequired = (state) => state.authentication.newPasswordRequired
export const selectMenuSelectedIndex = (state) => state.authentication.menuSelectedIndex
export const selectAuthFactoryId = (state) => state.authentication.authFactoryId
export const selectToken = (state) => state.authentication.token
export const selectEmail = (state) => state.authentication.email
export const selectPhoneNumber = (state) => state.authentication.phoneNumber
export const selectResetCompleted = (state) => state.authentication.resetCompleted
export const selectOtpError = (state) => state.authentication.otpError

export default authenticationSlice.reducer