import * as Amplify from 'aws-amplify'
const { Auth } = Amplify

export const amplifyService = {
    checkUserCurrentStatus,
    loginToCognito,
    logoutFromCognito,
    forgotPassword,
    forgotPasswordSubmit,
    newPasswordRequiredSubmit,
    getAccessToken,
    getUserInfo
}

/**
 * This function will be called on every page load to check if the user is logged in or not.
 * If the user is logged in, it will return the user object, otherwise it will return .
 */
async function checkUserCurrentStatus() {

    try {

        return await Auth.currentAuthenticatedUser({
            bypassCache : true
        })

    } catch (error) {
        throw error
    }

}

async function loginToCognito(email, password) {

    try {
        
        const user = await Auth.signIn(email, password)
        return user
        
    } catch(error) {
        throw error   
    }

}

async function logoutFromCognito() {
    
    try {

        const currentUser = Auth.userPool.getCurrentUser()        
        await currentUser.signOut()

    } catch (error) {
        throw error
    }

}

async function forgotPassword(email) {

    Auth.forgotPassword(email)
        .then(data => {
            return data
        })
        .catch(error => {
            throw error
        })

}

async function forgotPasswordSubmit(email, otp, password) {

    const promise = new Promise(function(resolve, reject) {

        Auth.forgotPasswordSubmit(email, otp, password)
            .then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })    

    })

    return promise

}

async function newPasswordRequiredSubmit(user, password) {

    const promise = new Promise(function(resolve, reject) {

        Auth.completeNewPassword(user, password)
            .then(user => {
                resolve(user)
            })
            .catch(error => {
                reject(error)
            })    

    })

    return promise
}

/**
 * Get the access token.
 * Important as this is the credentials to use when accessing the REST API.
 */
async function getAccessToken() {

    const promise = new Promise(function(resolve, reject) {

        Auth.currentSession()
            .then((data) => {
                resolve(data.idToken.jwtToken)
            })
            .catch(error => {
                reject(error)
            })

    })

    return promise

}

async function getUserInfo() {

    const promise = new Promise(function(resolve, reject) {

        Auth.currentUserInfo()
            .then(res => {

                if (res !== null) {

                    resolve({
                        email           : res.attributes["email"],
                        phone_number    : res.attributes["phone_number"],
                        factory_id      : res.attributes["custom:factory_id"],
                        distributor_id  : res.attributes["custom:distributor_id"],
                        role            : res.attributes["custom:role"]
                    })

                }


            })
            .catch(error => {
                reject(error)
            })

    })

    return promise

}
